<template>
  <b-container
      class="bv-example-row mb-3"
      fluid
  >
    <hr>
    <div>
      <b-card-actions
          class="cari-liste-card"
          ref="rapor-card"
          @refresh="refreshStop('rapor-card')"
      >
        <b-row class="mt-50 mb-3">
          <div class="card-header-row header-row-in-v-select"
               :style="{backgroundColor: $store.getters.cardTitle}"
          >
            <b-col cols="12">
              <h4 class="text-white d-inline-block">{{ $store.getters.yil }} Yılı {{ $store.getters.ay }}
                Ayı Oda
                Bazlı Konaklama Raporu</h4>
            </b-col>
          </div>
        </b-row>
        <hr>
        <b-row>
          <b-col lg="12" md="12" sm="12" cols="12" class="d-flex justify-content-end">
            <v-select
                @input="changeYear"
                v-model="year"
                :options="years"
                class="float-right"
                :style="{minWidth : width < 1000 ? '40%':''}"
            />
            <v-select
                @input="changeMonth"
                v-model="month"
                :options="months"
                class="ml-50"
                :style="{minWidth : width < 1000 ? '60%':''}"
            />
          </b-col>
          <b-col lg="12" md="12" sm="12" cols="12" :class="width < 1000 ?['mt-2']:''">
            <h6 class="info-text">
              Full Oda
            </h6>
            <h6 class="info-text">
              Oda Kapatma
            </h6>
            <h6 class="info-text">
              Rezervasyon Mevcut
            </h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="text-center">
            <b-spinner style="width: 3rem; height: 3rem" v-if="!$store.getters.odalar"/>
          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="$store.getters.odalar">
          <b-col cols="12">
            <table class="table" :class="width <= 1380 ?['table-responsive']:''">
              <thead>
              <tr>
                <td :style="{backgroundColor: $store.getters.tableTitle}">
                  <h5
                      class="text-white font-weight-bolder m-0 text-center"
                  >
                    Oda No
                  </h5>
                </td>
                <th v-for="ay in $store.getters.aySonuRez" :key="ay" scope="col"
                    :style="{backgroundColor: $store.getters.tableTitle}"
                >
                  <p class="text-white">{{ ay }}</p>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="oda in $store.getters.odalar" :key="oda.odaKodu">
                <td class="text-center oda-kodu"><h5 class="font-weight-bolder">{{ oda.odaKodu }}</h5></td>
                <td v-for="(item, index) in $store.getters.aySonuRez" :key="index">
                  <div class="detail-box" :style="{
                      cursor: durum(item, oda.odaKodu) && !durum(item, oda.odaKodu) ? 'pointer' : 'default',
                      backgroundColor:
                      durum(item, oda.odaKodu).odaKapatma == 1
                          ? '#fc4b6c'
                          : parseInt(oda.odaYatak) + parseInt(oda.odaEkYatak) ==
                            isDurumFullOda(item, oda.odaKodu)
                            ? '#7460ee'
                            : durum(item, oda.odaKodu)
                              ? '#0b547b'
                              : !durum(item, oda.odaKodu) &&
                                isDateControl(item) < isDate()
                                ? '#c8d8d2'
                                : isDateControl(item) < isDate()
                                  ? 'black'
                                  : '#c8d8d2',
                    }"
                  >
                    <div
                        v-if="durum(item, oda.odaKodu)"
                        v-b-modal.show-rez
                        class="rez-show text-white font-weight-bolder"
                        @click="rezShow(item, oda)"
                    >
                      Dolu
                      <!--                      <feather-icon-->
                      <!--                          icon="SearchIcon"-->
                      <!--                          class="text-white"-->
                      <!--                      />-->
                    </div>
                    <div
                        v-if="!durum(item, oda.odaKodu)"
                        @click="rezOlustur(item,oda)"
                        class="rez-show font-weight-bold cursor-pointer"
                    >
                      <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                      />
                    </div>
                    <p class="feedback-text">
                      <!--                  {{ isDurumFullOda(item, oda.odaKodu) ? 'Full Oda' : durum(item, oda.odaKodu) ? 'orange' : '' }}    -->
                      <!--                      {{-->
                      <!--                        durum(item, oda.odaKodu).odaKapatma == 1-->
                      <!--                            ? 'Kapatma'-->
                      <!--                            : parseInt(oda.odaYatak) + parseInt(oda.odaEkYatak) ==-->
                      <!--                            isDurumFullOda(item, oda.odaKodu)-->
                      <!--                                ? 'FULL ODA'-->
                      <!--                                : durum(item, oda.odaKodu)-->
                      <!--                                    ? 'Dolu Oda'-->
                      <!--                                    : !durum(item, oda.odaKodu) &&-->
                      <!--                                    isDateControl(item) < isDate()-->
                      <!--                                        ? 'var demekki bişey'-->
                      <!--                                        : isDateControl(item) < isDate()-->
                      <!--                                            ? 'var demekki bişey 2'-->
                      <!--                                            : ''-->
                      <!--                      }}-->
                    </p>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
    <b-modal
        id="show-rez"
        centered
        size="xl"
        header-bg-variant="white"
        hide-footer
    >
      <b-row>
        <b-col
            cols="12"
            class="mb-3"
        >
          <h1 class="text-center">
            Rezervason Bilgileri
          </h1>
        </b-col>
      </b-row>
      <table class="table modal-thead-table">
        <thead class="modal-thead">
        <tr>
          <th scope="col">
            KONUK BİLGİLERİ
          </th>
          <th scope="col">
            GİRİŞ
          </th>
          <th scope="col">
            ÇIKIŞ
          </th>
          <th scope="col">
            KONAKLAMA
          </th>
          <th scope="col">
            GÜNLÜK ÜCRET
          </th>
          <th scope="col">
            TOPLAM ÜCRET
          </th>
          <th scope="col"/>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(rezervasyon, index) in this.$store.state.rezervasyon
              .rezervasyonKonukListesi"
            :key="index"
        >
          <td>
            <div class="d-flex align-items-center">
              <b-avatar
                  class="pull-up mr-2"
                  :style="{
                    backgroundColor:
                      rezervasyon.cinsiyet == 1
                        ? $store.getters.bgInfo
                        : $store.getters.bgDanger,
                  }"
                  :text="
                    rezervasyon.adi ? rezervasyon.adi.charAt(0) : ' '+
                      '.' +
                      rezervasyon.soyAdi ? rezervasyon.soyAdi.charAt(0) : ' '
                  "
                  badge=""
                  badge-variant="success"
                  variant="primary"
              />
              <div class="d-flex flex-column">
                  <span class="fw-bolder userAdi">
                    {{ rezervasyon.adi }} {{ rezervasyon.soyAdi }}
                    <span
                        v-if="rezervasyon.onlineRezervasyon == 1"
                        style="
                        background-color: #6f3131 !important;
                        color: white !important;
                      "
                        class="badge badge-light-danger onRezBadge"
                    >Online Rezervasyon</span>
                    <span
                        v-if="rezervasyon.onRezervasyon == 1"
                        style="
                        background-color: #696f31 !important;
                        color: white !important;
                      "
                        class="badge badge-light-danger onRezzBadge"
                    >Ön Rezervasyon</span>
                    <span
                        v-if="rezervasyon.checkOut == 1"
                        style="background-color: #696f31 !important"
                        class="badge badge-bgWarning cikisRezBadge"
                    >Çıkış Yapıldı</span>
                  </span>
                <small class="emp_post text-muted myTelBadge">
                    <span class="badge badge-light-primary smallBadge">{{
                        rezervasyon.tarifeText
                      }}</span>
                  <span
                      class="badge badge-light-primary smallBadge"
                  >TC:{{ rezervasyon.tcKimlik }}</span>
                  <b-badge
                      variant="primary"
                      class="badge-light-primary"
                  >
                    <feather-icon
                        icon="PhoneIcon"
                        class="mr-25"
                    />
                    <span>{{ rezervasyon.tel }}</span>
                  </b-badge>
                </small>
              </div>
            </div>
          </td>
          <td class="text-center">
            <b-button
                v-ripple.400="'rgba(130, 134, 139, 0.2)'"
                variant="flat-primary"
                class="inputTarih"
                :disabled="true"
            >
              {{ rezervasyon.girisTarih }}
            </b-button>
          </td>
          <td class="text-center">
            <b-button
                v-ripple.400="'rgba(130, 134, 139, 0.2)'"
                variant="flat-primary"
                class="inputTarih"
                style="white-space: nowrap"
                :disabled="true"
            >
              {{ rezervasyon.cikisTarih }}
            </b-button>
          </td>
          <td class="text-center">
            <b-badge
                pill
                variant="cardTitle-L"
                class="pVerilcek"
            >{{ rezervasyon.period }} Gece
            </b-badge>
          </td>
          <td class="text-center">
            <b-badge
                class="gunluk-ucret"
                style="cursor: default"
                href="javascript:void(0)"
                pill
                variant="cardTitle-L"
                :disabled="true"
            >
              {{ rezervasyon.fiyat }} TL
            </b-badge>
          </td>
          <td class="text-center">
            <b-badge
                pill
                variant="cardTitle-L"
                class="pVerilcek"
            >{{ rezervasyon.period * rezervasyon.fiyat }} TL
            </b-badge>
          </td>
          <td>
            <b-button
                variant="bgInfo"
                class="rez-go-btn"
                :to="
                  '/rezervasyon/' +
                    $store.state.rezervasyon.rezervasyonBilgileri.rezervasyonNo
                "
                size="sm"
            >
              <feather-icon
                  icon="LogInIcon"
                  class="mr-50"
              />
              <span class="align-middle">Rezervasyona Git</span>
            </b-button>
          </td>
        </tr>
        </tbody>
      </table>
    </b-modal>
  </b-container>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BAvatar,
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BFormGroup,
  BSpinner,
  BFormCheckbox,
  BFormCheckboxGroup,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { useWindowSize } from '@vueuse/core'

export default {
  components: {
    BCardActions,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BFormGroup,
    BSpinner,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BModal,
    VBModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      spinner: true,
      showRez: [],
      selected: [],
      adi: '',
      soyAdi: '',
      tcKimlik: '',
      telefon: '',
      fiyat: '',
      aciklama: '',
      tarife: {
        title: 'Üye',
        id: 3,
      },
      tarifeOps: [
        {
          title: 'Üye',
          id: 3,
        },
        {
          title: 'Kamu',
          id: 1,
        },
        {
          title: 'Sivil',
          id: 2,
        },
      ],
      cinsiyet: {
        title: 'Erkek',
        id: 1,
      },
      cinsiyetOps: [
        {
          title: 'Erkek',
          id: 1,
        },
        {
          title: 'Kadın',
          id: 0,
        },
      ],
      odaKapatma: {
        title: 'Hayır',
        id: 0,
      },
      odaKapatmaOps: [
        {
          title: 'Hayır',
          id: 0,
        },
        {
          title: 'Evet',
          id: 1,
        },
      ],
      years: [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
      months: [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık',
      ],
      year: 2023,
      month: 'Ocak',
      filtre: false,
    }
  },
  computed: {
    ayGet() {
      switch (this.month) {
        case 'Ocak':
          return '01'
          break
        case 'Şubat':
          return '02'
          break
        case 'Mart':
          return '03'
          break
        case 'Nisan':
          return '04'
          break
        case 'Mayıs':
          return '05'
          break
        case 'Haziran':
          return '06'
          break
        case 'Temmuz':
          return '07'
          break
        case 'Ağustos':
          return '08'
          break
        case 'Eylül':
          return '09'
          break
        case 'Ekim':
          return '10'
          break
        case 'Kasım':
          return '11'
          break
        case 'Aralık':
          return '12'
          break

        default:
          break
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('clearHizliRez')
  },
  created() {
    this.refreshStop()
    const today = new Date()
    const month = String(today.getMonth() + 1)
        .padStart(2, '0')
    const yyyy = today.getFullYear()
    this.year = yyyy
    this.month = this.ayGetTurkishType(month)
    this.filtre = true
    this.$store
        .dispatch('hizliRez', {
          year: this.year,
          month,
        })
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  methods: {
    rezOlustur(item, oda) {
      const date = this.year + '-' + this.ayGetTurkishTypeReverse(this.month) + '-' + item
      localStorage.setItem('otelWorkDay', date)
      this.$router.push({
        name: 'yeniRezervasyon',
        params: { id: oda.odaKodu },
      })
    },
    changeMonth(newVal) {
      this.$refs['rapor-card'].showLoading = true
      this.$store.dispatch('hizliRezMonth', {
        year: this.year,
        month: newVal,
      })
          .then(() => this.$refs['rapor-card'].showLoading = false)
    },
    changeYear(newVal) {
      this.$refs['rapor-card'].showLoading = true
      const ay = this.ayGets()
      this.$store.dispatch('hizliRezYear', {
        year: newVal,
        month: ay,
      })
          .then(() => this.$refs['rapor-card'].showLoading = false)
    },
    rezShow(index, rez) {
      const { konuklar } = this.$store.getters
      if (konuklar[index][rez.odaKodu]) {
        const show = konuklar[index][rez.odaKodu].rezervasyonBilgileri
        const rezNo = Object.values(show)[0].rezervasyonNo
        this.$store.dispatch('rezervasyonGo', rezNo)
            .then(res => {
              this.showRez = res.rezervasyonKonukListesi
            })
      }
    },
    isDateControl(day) {
      day = String(day)
          .padStart(2, '0')
      const yil = this.year
      const ay = this.ayGets()
      return `${yil}/${ay}/${day}`
    },
    isDate() {
      const today = new Date()
      const dd = String(today.getDate())
          .padStart(2, '0')
      const mm = String(today.getMonth() + 1)
          .padStart(2, '0') // January is 0!
      const yyyy = today.getFullYear()
      return `${yyyy}/${mm}/${dd}`
    },
    dayCL(day) {
      return (day = String(day)
          .padStart(2, '0'))
    },
    ayGets() {
      switch (this.month) {
        case 'Ocak':
          return '01'
          break
        case 'Şubat':
          return '02'
          break
        case 'Mart':
          return '03'
          break
        case 'Nisan':
          return '04'
          break
        case 'Mayıs':
          return '05'
          break
        case 'Haziran':
          return '06'
          break
        case 'Temmuz':
          return '07'
          break
        case 'Ağustos':
          return '08'
          break
        case 'Eylül':
          return '09'
          break
        case 'Ekim':
          return '10'
          break
        case 'Kasım':
          return '11'
          break
        case 'Aralık':
          return '12'
          break

        default:
          break
      }
    },
    durum(index, odaNo) {
      const { konuklar } = this.$store.getters
      if (konuklar[index][odaNo]) {
        if (konuklar[index][odaNo].rezervasyonBilgileri) {
          for (const [key, value] of Object.entries(
              konuklar[index][odaNo].rezervasyonBilgileri,
          )) {
            return value
          }
        }
      } else {
        return false
      }
    },
    isDurumFullOda(index, odaNo) {
      const { konuklar } = this.$store.getters
      if (konuklar[index][odaNo]) {
        if (konuklar[index][odaNo].rezervasyonBilgileri) {
          for (const [key, value] of Object.entries(
              konuklar[index][odaNo].rezervasyonBilgileri,
          )) {
            return Object.keys(konuklar[index][odaNo].rezervasyonBilgileri)
                .length
          }
        }
      } else {
        return false
      }
    },
    rezGo(rezNo) {
      this.$router.push(`/rezervasyon/${rezNo}`)
    },
    ayGetTurkishType(ay) {
      switch (ay) {
        case '01':
          return 'Ocak'
          break
        case '02':
          return 'Şubat'
          break
        case '03':
          return 'Mart'
          break
        case '04':
          return 'Nisan'
          break
        case '05':
          return 'Mayıs'
          break
        case '06':
          return 'Haziran'
          break
        case '07':
          return 'Temmuz'
          break
        case '08':
          return 'Ağustos'
          break
        case '09':
          return 'Eylül'
          break
        case '10':
          return 'Ekim'
          break
        case '11':
          return 'Kasım'
          break
        case '12':
          return 'Aralık'
          break

        default:
          break
      }
    },
    ayGetTurkishTypeReverse(ay) {
      switch (ay) {
        case 'Ocak':
          return '01'
          break
        case 'Şubat':
          return '02'
          break
        case 'Mart':
          return '03'
          break
        case 'Nisan':
          return '04'
          break
        case 'Mayıs':
          return '05'
          break
        case 'Haziran':
          return '06'
          break
        case 'Temmuz':
          return '07'
          break
        case 'Ağustos':
          return '08'
          break
        case 'Eylül':
          return '09'
          break
        case 'Ekim':
          return '10'
          break
        case 'Kasım':
          return '11'
          break
        case 'Aralık':
          return '12'
          break
        default:
          break
      }
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'rapor-card'].showLoading = false
      }, 2000)
    },
  },
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },

}
</script>

<style scoped>
.cikisRezBadge {
  width: 85px;
}

.rez-show .feather {
  //transform: translate(16px, 5px);
}

.rez-show {
  height: 52px;
  display: flex;
  place-items: center;
  justify-content: center;
}

label input[id="customCheck"] ~ span {
  display: inline-block;
  vertical-align: middle;
  height: 32px !important;
  width: 50px !important;
  position: absolute;
  margin-top: -18px !important;
  margin-left: -15px;
}

input[id="customCheck"]:checked ~ div label {
  /* background: url(http://ramyasspace.files.wordpress.com/2011/06/tick.jpg); */
  background: url(https://cdn-icons-png.flaticon.com/512/1828/1828643.png);
  background-size: 100% !important;
  background-position-y: top !important;
}

#customCheck {
  display: none;
}

label input[id="customCheck"] ~ span {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  /* background: #fff; */
  border: 1px solid #888;
  padding: 1px;
  height: 32px !important;
  width: 50px !important;
}

label input[type="checkbox"]:checked ~ span {
  background: url("https://img.icons8.com/nolan/344/checkmark.png") !important;
  background-size: 100% !important;
  background-position-y: -8px !important;
  background-color: #2196f3 !important;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  text-align: center;
}

[dir] .table th,
[dir] .table td {
  width: 50px !important;
}

.row {
  justify-content: space-between;
}

.chck {
  border-right: 1px solid white !important;
}

.container {
  display: block;
  position: relative;
  /* padding-left: 35px; */
  /* margin-bottom: 12px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

[dir] .btn-secondary {
  border-color: #82868b !important;
  background-color: #82868b !important;
}

p {
  padding: 0 !important;
  margin: 0 !important;
}

table {
  border: 1px solid grey;
}

[dir] .table th,
[dir] .table td {
  padding: 0 !important;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.modal-thead {
  height: 35px !important;
}

.modal-thead th {
  vertical-align: middle !important;
}

.modal-thead-table {
  border: none !important;
}

.rez-go-btn,
.gunluk-ucret {
  color: white !important;
}

.rez-go-btn :hover {
  color: white !important;
}

.gunluk-ucret :hover a {
  color: white !important;
}

.onRezBadge {
  width: 130px;
  padding: 5px;
  margin-right: 4px;
}

.smallBadge {
  margin-right: 5px;
  font-weight: 500;
}

.onRezzBadge {
  width: 120px;
  padding: 5px;
}

.myTelBadge {
  margin-top: 5px;
}

.userAdi {
  font-weight: 900;
}

.white {
  color: white !important;
}

.v-select {
  background-color: white;
  border-radius: 40px;
  transform: translate(0px, 1.5px);
  z-index: 800 !important;
  width: 20% !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.header-row-in-v-select .vs--single .vs__dropdown-toggle {
  padding-left: 6px;
  border: none !important;
  height: 30px;
  transform: translate(0px, -5px);
}

.detail-box {
  height: 50px;
  width: 98%;
  margin: 2px;
  border-radius: 2px;
}

.detail-box:hover {
  opacity: 0.8;
}

.feedback-text {
  color: white;
  font-size: 15px;
}

.oda-kodu {
  background-color: #A78295;
}

.oda-kodu h5 {
  color: white;
  margin: 0;
}

.info-text {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
}

.info-text:nth-child(1) {
  background-color: #7460ee;
  color: white;
  margin-right: 0.5rem;
}

.info-text:nth-child(2) {
  background-color: #fc4b6c;
  color: white;
  margin-right: 0.5rem;
}

.info-text:nth-child(3) {
  background-color: #0b547b;
  color: white;
  margin-right: 0.5rem;
}
</style>
